import React from 'react';
import styles from './style.module.scss';
import Image from 'next/image';
import Link from 'next/link';

interface HomeDecoritemsProps {
  // data: any;
}
const HomeDecoritems: React.FC<HomeDecoritemsProps> = () => {
  const postersData = [
    {
      imgSrc: '/images/home-decor/home-decor1.jpg',
      alt: 'Home Decor Online Tableware',
      itemsname: 'Tableware',
      itemsprice: 'From ₹116',
    },
    {
      imgSrc: '/images/home-decor/home-decor2.jpg',
      alt: 'Home Decor Online Teapots',
      itemsname: 'Teapots',
      itemsprice: 'From ₹499',
    },
    {
      imgSrc: '/images/home-decor/home-decor3.jpg',
      alt: 'Home Decor Online  Cutlery Holder',
      itemsname: 'Cutlery Holder',
      itemsprice: 'From ₹519',
    },
    {
      imgSrc: '/images/home-decor/home-decor4.jpg',
      alt: 'Home Decor Online Dinner Sets',
      itemsname: 'Dinner Sets',
      itemsprice: ' From ₹795',
    },
    {
      imgSrc: '/images/home-decor/home-decor5.jpg',
      alt: 'Home Decor Online Glassware',
      itemsname: 'Glassware',
      itemsprice: '  From ₹177',
    },
    {
      imgSrc: '/images/home-decor/home-decor6.jpg',
      alt: 'Home Decor Online  Platters',
      itemsname: 'Platters',
      itemsprice: '  From ₹589',
    },
    {
      imgSrc: '/images/home-decor/home-decor7.jpg',
      alt: 'Home Decor Online Serving Trays',
      itemsname: 'Serving Trays',
      itemsprice: '  From ₹323',
    },
    {
      imgSrc: '/images/home-decor/home-decor8.jpg',
      alt: 'Home Decor Online  Salt & Pepper Shakers',
      itemsname: 'Salt & Pepper Shakers',
      itemsprice: '  From ₹329',
    },
  ];
  return (
    <>
      <section className={`${styles['home-decor-section']}`}>
        <div className="home-container-1600">
          <div className={`${styles['home-decor-inner']}`}>
            <div className="wdRow ">
              <div className="wdCol-lg-12">
                <div className={`${styles['home-decor-heading']}`}>
                  <div className={styles.furnishheadingleft}>
                    <h2> Home Decor Items</h2>
                    <p>Stylish Décor to Brighten Every Festival</p>
                  </div>
                  <Link href="/home-furnishing" className="view-all-btn">
                    View All
                    <Image
                      src={'/images/categories/view-all-link-arrow.svg'}
                      alt="media"
                      width={17}
                      height={17}
                      quality={75}
                      loading="lazy"
                    />
                  </Link>
                </div>
              </div>
            </div>
            <div className={styles.furnishcontent}>
              <div className="wdRow">
                {postersData.map((poster, index) => (
                  <div
                    className={`${styles['col-px']} wdCol-lg-3 wdCol-md-3 wdCol-sm-6 wdCol-12`}
                    key={index}
                  >
                    <Link href={'#'} className={styles.furnishcard}>
                      <div className={styles.furnishimg}>
                        <figure>
                          <Image
                            src={poster.imgSrc}
                            alt={poster.alt}
                            width={418}
                            height={363}
                            quality={100}
                            loading="lazy"
                          />
                        </figure>
                      </div>
                      <div className={styles.furnishtext}>
                        <h4>{poster.itemsname}</h4>
                        <p>{poster.itemsprice}</p>
                      </div>
                    </Link>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default HomeDecoritems;
